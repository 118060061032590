<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">featured images</div>
        <div class="module-preview--contents">
            <div class="featured-images">
                <div class="image-wrapper" v-for="image in imageIds" :key="image">
                    <img :src="image" alt="">
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
    import Widget from "@/mixins/Widget";
    import {mapGetters} from "vuex";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                imageIds: []
            }
        },
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId'
            })
        },
        created() {
            this.imageIds = JSON.parse(this.getValue('images'));
        }
    }
</script>

<style scoped>
    .module-preview--contents{
        grid-template-columns: 1fr;
    }
    .module-preview--contents .featured-images{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 25px;
    }
    .module-preview--contents .image-wrapper{
        height: 100%;
        max-height: 250px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    } 
</style>